.btn-primary {
  color: #fff;
  background-color: $brand-color;
  border-color: $brand-color;

  &:active,
  &:hover,
  &:disabled {
    background-color: $brand-hover;
    border-color: $brand-hover;
  }
}

.btn-default {
  color: #524e4e;
  background-color: transparent;
  border-color: #a2a7ab;

  &:active,
  &:hover {
    background-color: #d4d4d4;
  }
}
