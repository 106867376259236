@import './mixins.scss';

.desktop {
  display: block;
}

.mobile {
  display: none;
}

.settings-table-heading {
  padding-bottom: 24px;
}

.fleet-page-wrap {
  padding: 60px 0;
  background-color: #ecedef;
  line-height: normal;
}

.settings-page-wrap {
  padding: 60px 0;
  background-color: #ecedef;
  line-height: normal;
  margin: 0 96px;
}

.zc-heading {
  font-size: 24px;
  font-weight: 400;
  color: #2b343b;
}

.search-filter-container {
  position: fixed;
  height: 56px;
  width: 100%;
  top: 106px;
  border-radius: 2px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  background-color: #fff;
  z-index: 1070;
}

.bar-top {
  height: 2px;
  background-color: #e2e4e5;
}

.search-filter {
  @include flexbox();
  @include align-items(center);

  height: 54px;
  margin: 0 56px 0 96px;
  // padding-left: 40px;
}

.search-left,
.search-right {
  @include flexbox();
}

.back-button {
  cursor: pointer;
  // margin-left: 40px;
  margin-right: 24px;

  .back-arrow {
    height: 10px;
    width: 18px;
    position: relative;
    top: -1px;

    @include rotate(90deg);
  }

  .back-button-text {
    font-size: 16px;
    color: #293239;
  }

  & ~ .search-left {
    margin-left: 0;
  }
}

.dropdown-wrap {
  position: relative;

  .dropdown-head {
    height: 32px;
    border-radius: 4px;
    background-color: #e2e4e6;
    padding: 6px 12px 7px;
    line-height: 19px;
    margin-right: 10px;
    cursor: pointer;
    display: inline-block;

    .label,
    .range {
      font-size: 14px;
      color: #293239;
      // letter-spacing: 0.5px;
    }

    .label {
      font-weight: 600;
    }

    .range {
      margin-left: 5px;
    }

    &.active {
      background-color: #2d8ed9;

      .label,
      .range {
        color: #f3f4f5;
      }
    }

    .dropdown-arrow {
      margin-left: 12px;
      width: 14px;
      height: 6px;
    }
  }

  .md-drppicker {
    width: 593px;
    height: 260px;
    border-radius: 2px;
    border: solid 1px #bdbdbd;
    overflow: hidden;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0), 0 0 0 0 rgba(0, 0, 0, 0);
    padding: 0;
  }

  .md-drppicker.double {
    width: 593px;
  }

  .md-drppicker .calendar {
    max-width: 270px;
    margin: 0;
  }

  .table-condensed {
    width: 288px;
    margin: 0;
  }

  .md-drppicker .calendar-table {
    padding: 0;
  }

  .calendar th {
    border-radius: 0;
    background-color: #e2e4e6;
    color: #293239;
    font-size: 12px;
    font-weight: bold;
    width: 17.9px;
    height: 37px;

    &.available.prev,
    &.available.next,
    &.drp-animate,
    &.month {
      background-color: white;
    }

    &.available.prev,
    &.available.next {
      color: #293239;
      font-weight: normal;
    }

    &.drp-animate,
    &.month {
      color: #293239;
      font-weight: normal;
    }
  }

  .md-drppicker td.in-range {
    background-color: rgba(45, 142, 217, 0.2);
    border-color: rgba(45, 142, 217, 0.2);
  }

  .md-drppicker td {
    border: 0;
  }

  //To remove the hover effect
  .md-drppicker td.available:hover {
    background: white;
    border-radius: 0;
  }

  .md-drppicker td.in-range.available:hover {
    background-color: rgba(45, 142, 217, 0.2);
  }

  .md-drppicker td.active.end-date.available:hover {
    border-radius: 0 2em 2em 0;
  }

  .md-drppicker td.active.start-date.available:hover {
    border-radius: 2em 0 0 2em;
  }

  .md-drppicker.ltr .calendar.right {
    margin-left: 0;
    padding-left: 35px;
  }

  th:empty {
    background-color: white;
  }

  .md-drppicker td.off.available {
    background-color: white !important;
  }

  .md-drppicker td.off.available:hover {
    color: #999;
  }

  // .md-drppicker td.available:hover {

  // }
  .start-date {
    background-color: #2d8ed9 !important;
  }

  .end-date {
    background-color: #2d8ed9 !important;
  }

  .md-drppicker .end-date {
    border-color: white !important;
  }

  .drop-down {
    position: absolute;
    height: 0;
    overflow: hidden;
    z-index: 2;
    top: 100%;
    width: inherit;
    margin-top: 9px;
    border-radius: 1px;
    box-shadow:
      0 3px 14px 0 rgba(0, 0, 0, 0.12),
      0 8px 10px 0 rgba(0, 0, 0, 0.14),
      0 5px 5px 0 rgba(0, 0, 0, 0.2),
      0 2px 4px 0 rgba(41, 50, 57, 0.2);
    background-color: #fff;

    &.show {
      height: initial;

      &.date-dropdown {
        height: auto;
        width: inherit;
      }
    }

    .drop-down-content {
      padding: 24px 24px 0;

      .heading {
        font-size: 14px;
        font-weight: bold;
        color: #293239;
        // letter-spacing: 0.5px;
      }

      .options-group {
        @include flexbox();
        @include flex-direction(row);
        @include flex-wrap(wrap);

        min-width: 300px;
        width: auto;
        // display: flex;
        // flex-direction: row;
        // flex-wrap: wrap;
        margin-top: 24px;

        .btn.btn-option {
          border-radius: 16px;
          margin: 0 6px 6px 0;
          border: solid 1px rgba(91, 103, 112, 0.6);
          background-color: transparent;
          color: #5b6770;
          font-size: 14px;
          font-weight: normal;
          padding: 6px 12.3px 7px 13.5px;
          box-shadow: none;

          // &:focus,
          &.active,
          &:hover {
            background-color: #2d8ed9;
            color: #f3f4f5;
          }
        }
      }
    }
  }
}

.dropdown-apply {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  padding-bottom: 20px;
  padding-top: 20px;
  text-align: right;
  margin-right: 24px;
  color: #2d8ed9;

  a {
    cursor: pointer;
  }

  .dropdown-apply-export {
    float: right;
    margin-right: 24px;
  }
}

.zc-table-heading {
  @include flexbox();
  @include flex-wrap(wrap);
  @include align-items(center);

  height: 36px;

  .table-name {
    font-size: 24px;
    font-weight: 300;
    color: #2b343b;
  }

  .zc-table-toggle {
    margin-left: auto;

    .toggle-button {
      height: 32px;
      border-radius: 5px;
      background-color: #2d8ed9;
      padding: 6px 12px 7px;
      line-height: 19px;
      cursor: pointer;

      .label {
        font-size: 14px;
        color: #f3f4f5;
        letter-spacing: 0.5px;
        font-weight: 600;
      }

      .dropdown-arrow {
        margin-left: 12px;
        width: 14px;
        height: 6px;
      }
    }
  }
}

.no-records-card {
  border-radius: 2px;
  box-shadow:
    0 2px 1px 0 rgba(0, 0, 0, 0.12),
    0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.2);
  background-color: #fff;
}

.no-records {
  text-align: center;
  padding: 100px 20px;

  img {
    width: 36px;
    height: 36px;
  }

  .note,
  .retry {
    margin-top: 20px;
    font-size: 16px;
    color: #293239;
  }

  .sub {
    font-size: 14px;
  }

  .btn-retry {
    height: 32px;
    border-radius: 5px;
    color: #f3f4f5;
    background-color: #2d8ed9;
    line-height: 19px;
    box-shadow: none;
  }

  &.no-records-sm {
    padding: 30px 20px;
  }
}

.paginator-wrap {
  background-color: #fff;
  padding: 24px;
  margin: 0 0 0 auto;
  overflow: hidden;
}

.zc-app-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 90vh;
}

.zc-content-wrap {
  min-height: calc(90vh - 250px);
  justify-content: start;
}

.text-green {
  color: #1d811e !important;
}

.text-orange {
  color: #293239 !important;
}

.text-red {
  color: #ce0100 !important;
}

.arrow-down {
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid black;
  width: 10px;
  opacity: 0.54;
  float: right;
  position: absolute;
  top: calc(50% - 2px);
  right: 0;
}

.arrow-up {
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid black;
  width: 10px;
  opacity: 0.54;
  float: right;
  position: absolute;
  top: calc(50% - 4px);
  right: 0;
}

.modal-backdrop {
  z-index: 1003;
}

.modal {
  position: absolute;
  top: 20%;
}

@media only screen and (max-width: 1200px) {
  .no-records {
    padding: 70px 20px;
  }
}

@media only screen and (max-width: 1147px) {
  .desktop {
    display: none;
  }

  .mobile {
    display: block;
  }

  .search-filter-container {
    .search-filter {
      position: relative;
      // padding-left: 24px;
    }

    .search-right {
      height: 0;

      @include flex-direction(column);

      position: absolute;
      top: 100%;
      width: 340px;
      background: #fff;
      overflow: auto;
      padding: 0 25px;

      .dropdown-wrap {
        .dropdown-head {
          display: none;
        }

        .drop-down {
          height: initial;
          position: initial;
          width: 100%;
          box-shadow: none;
          min-height: 0;
          border-radius: 0;

          .drop-down-content {
            padding: 40px 0;
            width: 285px;
            border-bottom: solid 1px #bdbdbd;
          }
        }

        .md-drppicker {
          position: relative;
          width: 289px;
          height: 540px;
          border-radius: 2px;
          border: solid 1px #bdbdbd;
          overflow: hidden;
          box-shadow: 0;
        }
      }

      .dropdown-apply {
        height: 99px;
        line-height: 99px;
        text-align: center;
        padding-top: 0;
        margin-left: 20px;
      }

      &.show {
        height: calc(100vh - 120px);
        margin-left: 36%;
      }
    }
  }

  .no-records {
    padding: 60px 20px;
  }

  .md-drppicker.ltr .calendar.left .calendar-table {
    width: 280px;
  }

  .md-drppicker.ltr .calendar.right .calendar-table {
    width: 280px;
  }

  .dropdown-wrap .md-drppicker.ltr .calendar.right {
    padding-left: 0;
  }
}

@media only screen and (max-width: 900px) {
  .desktop {
    display: none;
  }

  .mobile {
    display: block;
  }

  .search-filter {
    margin: 0 0 0 40px;
  }

  .search-filter-container {
    .search-filter {
      position: relative;
      // padding-left: 24px;
    }

    .search-right {
      height: 0;

      @include flex-direction(column);

      position: absolute;
      top: 100%;
      width: 340px;
      background: #fff;
      overflow: auto;
      padding: 0 25px;

      .dropdown-wrap {
        .dropdown-head {
          display: none;
        }

        .drop-down {
          height: initial;
          position: initial;
          width: 100%;
          box-shadow: none;
          min-height: 0;
          border-radius: 0;

          .drop-down-content {
            padding: 40px 0;
            width: 285px;
            border-bottom: solid 1px #bdbdbd;
          }
        }

        .md-drppicker {
          position: relative;
          width: 289px;
          height: 540px;
          border-radius: 2px;
          border: solid 1px #bdbdbd;
          overflow: hidden;
          box-shadow: 0;
        }
      }

      .dropdown-apply {
        height: 99px;
        line-height: 99px;
      }

      &.show {
        height: calc(100vh - 120px);
        margin-left: 36%;
      }
    }
  }

  .settings-page-wrap {
    margin: 0 40px;
  }

  .no-records {
    padding: 60px 20px;
  }
}

@media only screen and (max-width: 600px) {
  .search-filter {
    margin: 0 -16px 0 0;
    // padding-left: 40px;
  }

  .fleet-page-wrap,
  .settings-page-wrap {
    padding: 24px 0 0;
  }

  .paginator-wrap {
    margin: 0 auto;
  }

  .no-records {
    padding: 50px 20px;
  }

  .desktop {
    display: none;
  }

  .mobile {
    display: block;
  }

  .search-filter-container {
    .search-filter {
      position: relative;
      // padding-left: 24px;
    }

    .back-button {
      margin-left: 24px;
      margin-right: 0;

      .back-button-text {
        display: none;
      }
    }

    .search-left {
      margin-left: 24px;
    }

    .search-right {
      height: 0;

      @include flex-direction(column);

      position: absolute;
      top: 100%;
      width: 100vw;
      background: #fff;
      overflow: auto;
      padding: 0 40px;

      .dropdown-wrap {
        .dropdown-head {
          display: none;
        }

        .drop-down {
          height: initial;
          position: initial;
          width: 100%;
          box-shadow: none;
          min-height: 0;
          border-radius: 0;

          .drop-down-content {
            padding: 40px 0;
            width: 100%;
            border-bottom: solid 1px #bdbdbd;

            .options-group {
              display: block;
              width: auto;
            }
          }
        }
      }

      .dropdown-apply {
        height: 99px;
        line-height: 99px;
      }

      &.show {
        height: calc(100vh - 120px);
        margin-left: 0%;
      }
    }
  }
}

.slick-slider {
  width: 100%;
  margin: auto;
}

.nav-btn {
  height: 47px;
  position: absolute;
  width: 26px;
  cursor: pointer;
  top: 100px !important;
}

.prev-slide.slick-disabled,
.next-slide.slick-disabled {
  pointer-events: none;
  opacity: 0.2;
}

.prev-slide {
  background: url('/assets/images/arrow-up-black.png') no-repeat scroll 0 0;
  width: 80px;
  height: 80px;
  border-radius: 96px;
  box-shadow:
    0 3px 8px 0 rgba(0, 0, 0, 0.12),
    0 1px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.2);
  background-color: rgba(255, 255, 255, 0.7);
  right: -33px;
}

.next-slide {
  background: url('/assets/images/arrow-up-black.png') no-repeat scroll -24px 0;
  width: 80px;
  height: 80px;
  border-radius: 96px;
  box-shadow:
    0 3px 8px 0 rgba(0, 0, 0, 0.12),
    0 1px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.2);
  background-color: rgba(255, 255, 255, 0.7);
  right: -33px;
}

.prev-slide:hover {
  background-position: 0 -53px;
}

.next-slide:hover {
  background-position: -24px -53px;
}

.slick-prev::before {
  font-size: 60px !important;
  line-height: 0 !important;
}

.slick-next::before {
  font-size: 60px !important;
  line-height: 0 !important;
  margin-left: -36px;
}

.pos-r {
  position: relative !important;
}

.prev {
  font-size: 30px;
  z-index: 1;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  background-color: rgba(255, 255, 255, 0.7);
  left: -26px;
  border-style: solid;
}

.next {
  font-size: 30px;
  z-index: 1;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  background-color: rgba(255, 255, 255, 0.7);
  right: -26px;
  border-style: solid;
}

.carousel-controls .control {
  position: absolute;
  cursor: pointer;
  top: 48%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: #fff;
  opacity: 0.7;
  outline: none;
}

.carousel-control-next,
.carousel-control-prev {
  width: 5%;
}

.view-link span:hover {
  background: #ecedef;
  cursor: pointer;
}

.btn-group:hover {
  cursor: pointer;
  background-color: #fff;
}

.slick-list {
  overflow: visible !important;
}

.incident-item {
  position: relative;
  padding-left: 28px;

  &.incident-total-incidents {
    padding: 0;
  }

  &::before {
    content: '';
    top: 0;
    left: -8px;
    position: absolute;
    width: 35px;
    height: 35px;
  }

  &.incident-speeding::before {
    background:
      url('/assets/images/markericons_v3/incidents_icon/Traffic-Speed-Violated.svg')
      no-repeat center;
    background-size: cover;
  }

  &.incident-cornering::before {
    background:
      url('/assets/images/markericons_v3/incidents_icon/Cornering.svg')
      no-repeat center;
    background-size: cover;
  }

  &.incident-lane-drift::before {
    background:
      url('/assets/images/markericons_v3/incidents_icon/Lane-Drift-Found.svg')
      no-repeat center;
    background-size: cover;
  }

  &.incident-acceleration::before {
    background:
      url('/assets/images/markericons_v3/incidents_icon/Harsh-Acceleration.svg')
      no-repeat center;
    background-size: cover;
  }

  &.incident-tailgating::before {
    background:
      url('/assets/images/markericons_v3/incidents_icon/Tail-Gating-Detected.svg')
      no-repeat center;
    background-size: cover;
  }

  &.incident-braking::before {
    background:
      url('/assets/images/markericons_v3/incidents_icon/Harsh-Braking.svg')
      no-repeat center;
    background-size: cover;
  }

  &.incident-stop-sign::before {
    background:
      url('/assets/images/markericons_v3/incidents_icon/Traffic-STOP-Sign-Violated.svg')
      no-repeat center;
    background-size: cover;
  }

  &.incident-distraction::before {
    background:
      url('/assets/images/markericons_v3/incidents_icon/Distracted-Driving.svg')
      no-repeat center;
    background-size: cover;
  }

  &.incident-others::before {
    background:
      url('/assets/images/markericons_v3/incidents_icon/Others.svg')
      no-repeat center;
    background-size: cover;
  }
}

.beta-graphic-version {
  position: relative;

  &::after {
    content: '';
    top: 50%;
    transform: translate(12px, -50%);
    position: absolute;
    width: 49px;
    height: 22px;
    background:
      url('/assets/images/beta_graphic.svg')
      no-repeat center;
    background-size: cover;
  }
}

.success-snackbar {
  min-width: fit-content;
  background: #fff;
  border-radius: 4px;
  border-left: 48px;
  border-top: 2px;
  border-right: 2px;
  border-bottom: 2px;
  border-color: #1d811e;
  border-style: solid;

  &::before {
    content: '';
    background:
      url('/assets/images/check-green-circle.svg')
      no-repeat center;
    background-size: cover;
    position: absolute;
    width: 20px;
    height: 20px;
    top: 14px;
    left: -33px;
  }

  .mat-simple-snackbar {
    align-items: flex-end;
    color: #292929;
    font-size: 16px;
    font-weight: 500;

    .mat-simple-snackbar-action {
      color: #0075b4;
      font-size: 16px;

      .mat-button-wrapper {
        font-weight: bold;
      }
    }
  }
}

.error-snackbar {
  min-width: 360px !important;
  background: #fff;
  border-radius: 4px;
  border-left: 48px;
  border-top: 2px;
  border-right: 2px;
  border-bottom: 2px;
  border-color: #ce0100;
  border-style: solid;
  white-space: pre-wrap;

  &::before {
    content: '';
    background:
      url('/assets/images/exclamation-white-circle.svg')
      no-repeat center;
    background-size: cover;
    position: absolute;
    width: 20px;
    height: 20px;
    top: 14px;
    left: -33px;
  }

  .mat-simple-snackbar {
    align-items: flex-end;
    inline-size: fit-content;
    color: #292929;
    font-size: 16px;
    font-weight: 500;

    .mat-simple-snackbar-action {
      color: #0075b4;
      font-size: 16px;

      .mat-button-wrapper {
        font-weight: bold;
      }
    }
  }

  &.multiline {
    .mat-simple-snackbar {
      align-items: center;
    }
  }
}

@media only screen and (max-width: 550px) {
  app-trip-details2 {
    .trip-summary {
      min-width: 550px;
      overflow-x: scroll;
    }

    .trip-info-wrap {
      min-width: 720px;
    }
  }
}

@media only screen and (max-width: 500px) {
  .search-filter-container {
    .back-button {
      margin-right: 12px;
    }
  }

  .no-records {
    padding: 40px 20px;
  }
}

@media only screen and (max-width: 400px) {
  .search-filter-container {
    .back-button {
      margin-left: 12px;
    }
  }

  .no-records {
    padding: 30px 20px;
  }
}

@media only screen and (min-width: 1552px) {
  .zc-content-wrap,
  .header-wrap {
    margin: unset;
    margin-left: calc(calc(100vw - 1360px) / 2);
    width: 1360px;
  }

  .search-filter {
    margin: unset;
    margin-left: calc(calc(100vw - 1360px) / 2);
    width: 1400px;
  }

  .settings-page-wrap {
    padding: 0 0;
    margin: 0;
  }
}
