/* You can add global styles to this file, and also import other style files */
@import 'variables';
@import 'mixins';
@import 'utility';
@import 'layout';
@import 'buttons';
@import 'custom';
@import 'table';
@import 'table3';
@import 'new-dashboard';
@import 'global';
@import 'global3';

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}
