html,
body {
  font-family: 'Roboto', sans-serif;
  overflow-x: hidden;
}

.info-widget {
  background-color: #e9edf0;
  border: 1px solid #ced4da;
  padding: 8px 10px;
  margin-bottom: 10px;
  height: 60px;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.16);
  -o-box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.16);
  -ms-box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.16);

  .left-block {
    display: inline-block;
    width: 16%;
    margin-right: 2%;
    vertical-align: top;

    i {
      font-size: 2.5rem;
      float: left;
      margin-right: 15px;
      width: 45px;
      min-width: 45px;
    }
  }

  .right-block {
    display: inline-block;
    width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;

    span {
      text-overflow: ellipsis;
      display: block;
      width: 100%;
      white-space: nowrap;
      overflow-x: hidden;
    }

    p {
      margin-bottom: 0;
    }
  }
}

@media (max-width: 768px) {
  .info-widget {
    .left-block {
      width: 7%;
    }
  }
}

@media print {
  body {
    zoom: 67%;
  }

  .search-filter-container {
    visibility: hidden;
  }
  // Remove Scrollbars

  .slick-slide {
    display: none !important;

    &.slick-active {
      display: block !important;
    }
  }

  .slick-track {
    width: auto !important;
    height: auto !important;
    transform: none !important;

    .scoreboard-card {
      width: 436px !important;
    }

    .tripeboard-card {
      width: 436px !important;
    }
  }

  .fleet-page-wrap {
    margin-top: -60px;
  }

  .triprecaps-wrap {
    margin-bottom: 200px;
  }

  .fleet_overclo {
    padding-left: 20px;
  }

  .disputed-trip-table-wrap {
    padding-top: 40px;
  }

  .col-sm-6 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-12 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .driver-snapshot {
    .col-sm-6 {
      flex: 0 0 20%;
      max-width: 20%;
    }

    .col-sm-12 {
      flex: 0 0 20%;
      max-width: 20%;
    }

    .col-sm-2 {
      flex: 0 0 18%;
      max-width: 18%;
    }
  }

  .driver-score-over-time {
    margin-bottom: 440px;
  }

  .disputed-incidents-table {
    padding-top: 40px;
  }

  .breadcrum {
    visibility: hidden;
  }

  .driver-page-wrap {
    margin-top: -150px;
  }

  .trip-summary {
    visibility: hidden;
  }

  .has-trip-summary {
    background-color: #fff;
  }

  .map {
    background-color: #fff;
  }

  .trippage-footer {
    margin-top: -300px;
  }
}

.box {
  padding: 15px;
  margin: 10px 0 10px;
  background-color: #dadada61;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.16);
}

.card {
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.16);

  .card-header {
    font-size: 18px;
    font-weight: 600;
  }
}

// Calendar and search button of calendar
.search-button {
  display: flex;
  align-items: center;
  top: 7px;

  button {
    margin: 0 6px 0 0;
  }
}

.datepicker {
  input {
    cursor: pointer;
  }

  .calendar-button {
    padding: 6px 12px;
    background: transparent;
    color: #8e9ba1;
    border-left: 0;
    border-right: 1px solid #c1d5e0;
    border-top: 1px solid #c1d5e0;
    border-bottom: 1px solid #c1d5e0;
    border-radius: 0;

    &:disabled {
      background-color: #e9ecef;
      opacity: 1;
    }
  }
}

//// Calendar and search button of calendar
.label-input-wrap {
  align-items: center;

  label {
    font-size: 18px;
    color: $box-heading;
  }
}

@media (max-width: 768px) {
  .label-input-wrap {
    button {
      float: right;
      margin-top: 10px;
    }
  }
}

.no-match-found {
  text-align: center;
  padding: 1rem;
  font-size: 1.5rem;
}

.no-content {
  padding: 2rem;
  text-align: center;
}

.text-blue {
  color: $brand-hover;
}

.form-control:focus {
  border-color: #999;
  outline: 0;
  -webkit-box-shadow:
    inset 0 1px 1px rgba(0, 0, 0, 0.15),
    0 0 5px rgba(100, 100, 100, 0.6);
  box-shadow:
    inset 0 1px 1px rgba(0, 0, 0, 0.15),
    0 0 5px rgba(100, 100, 100, 0.6);
}

.mat-form-field-appearance-outline {
  .mat-form-field-outline-thick {
    color: transparent;
  }

  &.mat-focused {
    .mat-form-field-outline-thick {
      color: #0075b4;
    }
  }

  .mat-form-field-label {
    margin-top: -0.4em;
  }

  .mat-form-field-outline {
    color: #b9bec1;
  }
}

.mat-form-field {
  .mat-form-field-label {
    color: #292929;
    font-size: 16px;
  }

  &.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    color: #5b6770;
  }

  &.mat-focused {
    &.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
      color: #0075b4;
    }
  }
}

.cdk-overlay-container {
  z-index: 1600;
}
