table.table-text-center {
  tr th {
    text-align: center;
    vertical-align: middle;
  }

  tr td {
    font-size: 14px;
    text-align: center;
  }
}

.table-fixed-header {
  .table {
    margin-bottom: 0;

    thead tr th {
      border-bottom: 0;
    }
  }
}

.table.table-fixed {
  thead {
    tr {
      margin-right: 15px;
    }
  }

  tbody {
    overflow-y: auto;
  }

  thead,
  tbody {
    tr {
      display: flex;
    }
  }

  thead,
  tbody,
  tr,
  td,
  th {
    display: block;
  }

  tbody td,
  thead > tr > th {
    line-height: 1rem;
    font-size: 15px;
    float: left;
    border-bottom-width: 0;

    &:last-child {
      border-right: 0;
    }
  }
}
