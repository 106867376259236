.leaflet-div-icon {
  background: transparent;
  border: none;
}

.leaflet-marker-icon .number {
  position: relative;
  top: -37px;
  font-size: 12px;
  width: 25px;
  text-align: center;
}
