// $brand-color: #00B1B0;
$brand-color: #4dadf7;
$brand-hover: #3792d8;
$box-heading: $brand-hover;
$brd-clr: #10d085;

$white: #fff;
$grey: #b7b2b2;
$mainColor: #00b1b0;
$activeColor: #3c4182;

$silver: #b5a6a6;
$light-blue: #edf0f6;
$light-grey: #edf0f6;
$white: #fff;
$grey: #7a8597;
$black: #3a3a3a;
$purple: #865cc5;

$yellow: #fac021;
$green: #35cebc;

$top-margin30: 30px;

$bold-font: 800;
$medium-font: 600;
$normal-font: 300;

$image-path: '../assets/images/';
