.app-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 90vh;
}

.content-wrap {
  min-height: calc(90vh - 200px);
  justify-content: start;
}
