// table
.table-100,
.height-100 {
  height: 100px;
  max-height: 100px;
}

.table-200,
.height-200 {
  height: 200px;
  max-height: 200px;
}

.table-300,
.height-300 {
  height: 300px;
  max-height: 300px;
}

.table-400,
.height-400 {
  height: 400px;
  max-height: 400px;
}

.table-500,
.height-500 {
  height: 500px;
  max-height: 500px;
}

// width
.width-5 {
  width: 5%;
}

.width-10 {
  width: 10%;
}

.width-15 {
  width: 15%;
}

.width-20 {
  width: 20%;
}

.width-25 {
  width: 25%;
}

.width-30 {
  width: 30%;
}

.width-33 {
  width: 33%;
}

.width-40 {
  width: 40%;
}

.width-45 {
  width: 45%;
}

.width-50 {
  width: 50%;
}

.width-60 {
  width: 60%;
}

.width-66 {
  width: 66%;
}

.width-70 {
  width: 70%;
}

.width-80 {
  width: 80%;
}

.pd-t-5 {
  padding-top: 5px;
}

.pd-r-5 {
  padding-right: 5px;
}

.pd-b-5 {
  padding-bottom: 5px;
}

.pd-l-5 {
  padding-left: 5px;
}

.h-09r {
  height: 1rem;
}

.fs-11 {
  font-size: 1.1rem;
}

.fs-12 {
  font-size: 1.2rem;
}

.fs-15 {
  font-size: 1.5rem;
}

.f-r {
  float: right;
}

.f-l {
  float: left;
}
