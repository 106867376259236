@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700');

@mixin rotate($deg: 90) {
  $sDeg: #{$deg}deg;

  -webkit-transform: rotate($sDeg);
  -moz-transform: rotate($sDeg);
  -ms-transform: rotate($sDeg);
  -o-transform: rotate($sDeg);
  transform: rotate($sDeg);
}

.zc-body {
  background: #ecedef;
  font-family: 'Open Sans', sans-serif;
  color: #293239;
  padding-top: 68px;

  &.has-search-filter {
    padding-top: 164px;
  }
}

.container {
  // background-color: #D0D3D5;
  padding: 0;
  max-width: 1440px;
}

.row {
  margin: 0;
}

// nav.navbar {
//     background-color: #dcdcdc;

//     .logo {
//         display: flex;
//         align-items: center;

//         i {
//             float: left;
//             font-size: 3rem;
//         }
//     }

//     ul.nav {
//         li.nav-item {
//             cursor: pointer;

//             &~i.fa {
//                 display: inline-block;
//                 content: '\f078';
//                 font-family: FontAwesome;
//                 margin-left: 3px;
//                 // line-height: 2rem;
//                 transform: rotate(0);
//                 transition: transform .50s;
//             }

//             &.open {
//                 &~i.fa {
//                     transform: rotate(-180deg);
//                 }
//             }
//         }
//     }

//     h4,
//     h6 {
//         margin-bottom: 0;
//     }

//     span {
//         display: block;
//         font-size: .7rem;
//         font-weight: 600;
//         text-transform: uppercase;
//     }
// }

.sub-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 2rem;
  margin: 1rem 0 0.5rem 0;
  padding: 0 2rem;
  font-size: 16px;

  .back-button {
    font-size: 1.4rem;
    font-weight: bold;
    vertical-align: middle;
    margin-right: 1rem;
    cursor: pointer;
  }

  .highlight {
    color: #000;
    text-decoration: underline !important;
    font-weight: 600;
    cursor: pointer;
  }

  .divider {
    border-left: 2px solid #b5b0b0;
    height: 100%;
    margin: 0 15px;
  }

  .export {
    cursor: pointer;
  }
}

.table {
  thead {
    tr {
      th {
        border: 0;
        font-weight: normal;
        background: #dadbdd;
      }
    }
  }

  tbody {
    tr {
      &:nth-of-type(odd) {
        background-color: transparent;
      }

      &:nth-of-type(even) {
        background-color: #e6e6e7;
      }
    }
  }

  &.table-hower {
    tbody tr {
      cursor: pointer;

      &:hover {
        background-color: #dadbdd;
      }
    }
  }
}

.score-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // background-color: #ededed;
  padding: 10px 2%;

  .left-section {
    & > * {
      vertical-align: middle;
    }

    i.fa {
      font-size: 1.8rem;
      background: #c9c9c9;
      width: auto;
      height: 100%;
      border-radius: 50%;
      text-align: center;
      padding: 15px;
      margin-right: 10px;
    }

    .status {
      display: inline-block;
      margin-right: 20px;

      .heading {
        font-size: 1.5rem;
        font-weight: 600;
      }

      .description {
        display: block;
        font-size: 1rem;
      }
    }
  }

  .score-widget {
    display: inline-block;
    text-align: center;
    margin: 0 10px;

    & > * {
      display: block;
    }

    .top {
      margin: 10px 5px;
      font-weight: 600;

      i.fa {
        font-size: 2.5rem; // background: #c9c9c9;
        width: auto;
        height: 100%; // border-radius: 50%;
        text-align: center; // vertical-align: middle;
      }
    }
  }
}

.box-widget {
  background-color: #fff;
  box-shadow:
    0 2px 9px 0 rgba(0, 0, 0, 0.22),
    4px 5px 16px 0 rgba(0, 0, 0, 0.27);

  .box-header,
  .box-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .box-header {
    .header-text {
      font-size: 18px;
      font-weight: 600;
      text-transform: capitalize;
    }

    .header-action {
      display: inherit;
      align-items: center;
    }
  }

  .box-footer {
    font-size: 0.9rem;
    text-transform: capitalize;
  }
}

.incident-video {
  text-align: center;

  video {
    width: 100%;
    height: auto;
  }
}

highcharts-chart {
  width: 100%;
  display: block;
}

// Search and filter bar dropdown css
// .drop-down-content {
//     padding: 24px;

//     .heading {
//         font-size: 14px;
//         font-weight: bold;
//         color: #293239;
//         letter-spacing: 0.5px;
//     }

//     .options-group {
//         display: flex;
//         flex-direction: row;
//         flex-wrap: wrap;
//         margin-top: 24px;

//         .btn.btn-option {
//             border-radius: 16px;
//             margin: 0 6px 6px 0;
//             border: solid 1px rgba(91, 103, 112, 0.6);
//             background-color: transparent;
//             color: #5b6770;
//             font-size: 14px;
//             padding: 6px 12.3px 7px 13.5px;
//             box-shadow: none;

//             &:focus,
//             &:active,
//             &:hover {
//                 background-color: #2d8ed9;
//                 color: #f3f4f5;
//             }
//         }
//     }
// }

.filter-choice {
  display: flex;

  .filter-element {
    border-bottom: 2px solid;
    border-color: #2d8ed9;
    padding-top: 24px;
    float: left;
    margin-right: 48px;
    min-width: 159px;
    max-width: 205px;
  }

  .filter-heading {
    height: 16px;
    font-size: 12px;
    line-height: 1.33;
    color: rgba(0, 0, 0, 0.38);
  }

  .filter-content {
    margin-bottom: 4px;
    height: 24px;
    font-size: 16px;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.87);
  }

  .content-row {
    margin-bottom: 4px;
    position: relative;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
