.import-from-file-modal {
  .modal-content {
    width: 530px;
    height: auto;
    min-height: 501px;

    .modal-header {
      padding-left: 24px;
      padding-top: 18px;
      padding-bottom: unset;
      border-bottom: unset;

      .modal-title {
        margin-bottom: unset;
      }
    }

    .modal-body {
      padding: 40px 0 0 0;

      .body-container {
        padding: 0 60px;

        .file-upload-container {
          width: 408px;
          height: auto;
          min-height: 290px;
          padding-top: 30px;
        }
      }
    }

    .modal-footer {
      border-top: unset;
    }
  }
}
