.zc-table-wrap {
  border-radius: 2px;
  box-shadow:
    0 2px 1px 0 rgba(0, 0, 0, 0.12),
    0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.2);
  background-color: #fff;
  overflow-x: auto;
}

.table.zc-table {
  margin-bottom: 0;
  table-layout: fixed;

  thead {
    tr {
      th {
        border: 0;
        background-color: #d0d3d5;
        height: 44px;
        vertical-align: middle;
        font-size: 12px;
        font-weight: 600;
        color: #293239;
        text-transform: uppercase;
        padding: 12px;

        &:first-child {
          padding-left: 24px;
        }

        &:last-child {
          padding-right: 24px;
        }
      }
    }
  }

  tbody {
    tr {
      &:nth-of-type(odd) {
        background-color: #fff;
      }

      &:nth-of-type(even) {
        background-color: #f3f4f5;
      }

      td {
        height: 44px;
        font-size: 12px;
        color: #293239;
        vertical-align: middle;
        border: 0;
        text-transform: capitalize;
        padding: 12px;

        &:first-child {
          padding-left: 24px;
        }

        &:last-child {
          padding-right: 24px;
        }

        img.arrow {
          height: 14px;
          width: 14px;
          margin-right: 6px;
        }

        img.minus {
          width: 12px;
          margin-right: 6px;
        }
      }
    }
  }

  &.table-hower {
    tbody tr {
      cursor: pointer;

      &:hover {
        background-color: #b9bec1;
      }
    }
  }

  &.table-text-wrap {
    td {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

table.header-sort {
  thead {
    tr {
      th {
        // padding-right: 15px;
        padding-left: 18px !important;

        &:first-child {
          padding-left: 36px !important;
        }

        .heading {
          cursor: pointer;
          // max-width: calc(100% - 30px);
          vertical-align: middle;
          display: inline;
          position: relative;

          .sort-arrow {
            position: absolute;
            float: left;
            top: 50%;
            transform: translateY(-50%);
            // right: -17px;
            left: -18px;

            img {
              position: relative;
              bottom: 1.5px;
              width: 13px;
              height: 7px;
              // padding-left: 3px;
              cursor: pointer;
              opacity: 0;

              &:hover {
                opacity: 1;
              }
            }
          }

          &:hover,
          &.selected {
            .sort-arrow {
              img {
                opacity: 1;
              }
            }
          }
        }
      }
    }

    // td {
    //     padding-right: 15px;
    // }
  }

  tbody {
    tr {
      td {
        padding-left: 18px !important;

        &:first-child {
          padding-left: 36px !important;
        }
      }
    }
  }
}
