// Custom checkbox
.btn.custom-check {
  border-radius: 0;
  padding: 0;
  width: 25px;
  height: 25px;
  color: #524e4e;
  background-color: #fafafa;
  border: 1px solid #a2a7ab;

  input {
    opacity: 0;
    cursor: pointer;
  }

  span.fa {
    opacity: 0;
  }

  &.active {
    color: #fff;
    background-color: #2d8ed9;
    border-color: #2d8ed9;

    & span.fa {
      opacity: 1;
    }
  }
}

// Custom Input
.form-group.custom-group {
  position: relative;
  margin-top: 1.5rem;

  label {
    font-weight: 600;
  }

  select {
    //remove default icon
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    width: 100%;
    font-size: 1rem;
    height: 1.6rem;
    padding: 0.125rem 0.125rem 0.0625rem;
    background: none;
    border: none;
    border-radius: 0;
    line-height: 1.6;
    box-shadow: none;

    // style for font-awesome icon
    & ~ i.fa {
      position: absolute;
      margin: auto;
      bottom: 7px;
      right: 5px;
      pointer-events: none;
      //  background-color: #fff;
      padding-right: 5px;
    }
  }

  input {
    height: 1.9rem;
    display: block;
    background: none;
    padding: 0.125rem 0.125rem 0.0625rem;
    font-size: 1rem;
    border-width: 0;
    border-color: transparent;
    line-height: 1.9;
    width: 100%;
    color: transparent;
    -webkit-transition: all 0.28s ease;
    transition: all 0.28s ease;
    box-shadow: none;
  }

  select,
  input {
    color: #333;

    & ~ .control-label {
      font-size: 0.8rem;
      color: gray;
      top: -1rem;
      left: 0;
    }

    &:focus {
      outline: none;

      & ~ .control-label {
        color: #337ab7;
      }

      & ~ .bar::before {
        width: 100%;
        left: 0;
      }
    }
  }

  .control-label {
    position: absolute;
    top: 0.25rem;
    pointer-events: none;
    padding-left: 0.125rem;
    z-index: 1;
    color: #b3b3b3;
    font-size: 1rem;
    font-weight: normal;
    -webkit-transition: all 0.28s ease;
    transition: all 0.28s ease;
  }

  .bar {
    position: relative;
    border-bottom: 0.0625rem solid #999;
    display: block;

    &::before {
      content: '';
      height: 0.125rem;
      width: 0;
      left: 50%;
      bottom: -0.0625rem;
      position: absolute;
      background: #4dadf7;
      -webkit-transition: left 0.28s ease, width 0.28s ease;
      transition: left 0.28s ease, width 0.28s ease;
      z-index: 2;
    }
  }
}

.modal.custom-modal {
  .modal-dialog .modal-content {
    border: 0;
    border-radius: 0;

    .modal-header {
      border-radius: 0;
      color: #fff;
      background: #4dadf7;

      button.close {
        color: #fff;
        opacity: 1;
      }
    }

    .modal-body {
      .custom-group {
        max-width: 300px;
        margin-top: 0;

        label {
          color: #a6a6a6;
        }
      }

      button {
        &.btn {
          border-radius: 0.2rem;
          padding: 0.3rem 1.75rem;
          font-size: 13px;
          font-weight: 600;

          &.btn-default {
            color: #4dadf7;
            background-color: #dfe1e4;
            border-color: #dfe1e4;

            &:active,
            &:hover {
              background-color: #c9ccd0;
              border-color: #c9ccd0;
            }
          }
        }
      }
    }
  }
}

.custom-radio {
  display: inline-block;
  position: relative;
  // padding: 0 6px;
  // margin: 10px 0 0;

  input[type='radio'] {
    display: none;
  }

  label {
    color: #666;
    font-weight: normal;
    margin: 0;
  }

  label::before {
    content: ' ';
    display: inline-block;
    position: relative;
    top: 5px;
    margin: 0 6px 0 0;
    width: 20px;
    height: 20px;
    border-radius: 11px;
    border: 2px solid #2d8ed9;
    background-color: transparent;
  }

  input[type='radio']:checked + label::after {
    border-radius: 11px;
    width: 10px;
    height: 10px;
    position: absolute;
    top: 10px;
    left: 5px;
    content: ' ';
    display: block;
    background: #2d8ed9;
  }
}
